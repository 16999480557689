<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.edit_project") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="formElements" class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="updateProject"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";

export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const estates = computed(() => store.state.estate.estates);
    const project = computed(() => store.state.project.project);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.project.waiting) return true;
      if (store.state.estate.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });
    const statuses = ref([
      { name: "todo", value: "todo" },
      { name: "doing", value: "doing" },
      { name: "done", value: "done" },
    ]);

    const formElements = ref(null);

    const setFormElements = () => {
      formElements.value = [
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "name",
          label: "name",
          rules: ["isRequired"],
          type: "text",
          value: project.value.name,
          variable: "name",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "select",
          id: "estate",
          label: "estate_name",
          optionLabel: ["name"],
          options: estates.value,
          rules: [],
          value: project.value.estateId,
          variable: "estateId",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "select",
          id: "status",
          label: "status",
          optionLabel: ["name"],
          options: statuses.value,
          rules: ["isRequired"],
          value: project.value.status,
          variable: "status",
          translatableLabel: "status",
        },
        {
          class: "col-span-12 text-right",
          element: "button",
          id: "submit-button",
          label: "save",
          textVariant: company.value.primaryText,
          type: "submit",
          variant: company.value.primary,
        },
      ];
    };

    const updateProject = (formData) => {
      const data = {
        companyId: project.value.companyId,
        estateId: formData.estateId,
        name: formData.name,
        status: formData.status,
      };
      store.dispatch("project/updateProject", {
        data,
        projectId: route.params.projectId,
      });
    };

    onMounted(() => {
      store.dispatch("estate/getEstates", user.value.companyId);
      if (!project.value.id || project.value.id !== route.params.projectId) {
        store.dispatch("project/getProject", route.params.projectId);
      } else {
        setFormElements();
      }
    });

    watch(
      () => waiting.value,
      (waiting) => {
        if (!waiting) {
          setFormElements();
        }
      }
    );

    return {
      formElements,
      t,
      updateProject,
      waiting,
    };
  },
};
</script>
